import React, { useState, useEffect } from 'react';
import {Navigation, Pagination, A11y } from 'swiper/modules';
import {Swiper, SwiperSlide} from "swiper/react";
import './App.css';
import 'swiper/swiper-bundle.css';

export const Slider = ({ slides }) => {


  const [swiperInstance, setSwiperInstance] = useState(null);
  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update();
    }
  }, [swiperInstance, slides]);


return (
  <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={0} // Space between slides
        slidesPerView={1} // Number of slides per view
        navigation
        pagination = {{clickable:true}}
        onSlideChange={() => console.log('slide change')}
        onSwiper={setSwiperInstance}
        loop={false}
        lazy={true}
      >
      {slides.map((slide) => (
        <SwiperSlide key={slide.image}>
        <div className=" slide-content">
          <img className="swiper-lazy img-responsive" src={slide.image} alt={slide.title}/>
          <div className="swiper-lazy-preloader image-overlay"></div>
          <div className="grey-cont"></div>
          <div className="title-overlay"><h1>{slide.title}</h1></div>
          <div class="container">
              <img className="round-image" src={slide.titleimage} alt={slide.title}/>
              <div className="content"><a href={slide.github} target={slide.github} rel="noopener noreferrer"  className="github"> <h2>{slide.github}</h2></a></div>
              <div className="description"><p>{slide.description}</p></div>
          
          </div>
        
        </div>
      </SwiperSlide>

      ))}

      </Swiper>
)



}