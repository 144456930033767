import React, { useEffect } from 'react';
import './App.css';
import { Slider } from './slider';
import slides from './projects.json';

function App() {
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    document.title = 'Adam - Portfolio';
  }, []);
  return (
    <div>
      <Slider slides={slides}/>
    </div>
  );

}

export default App;
